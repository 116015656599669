import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
      <div className="content center flex flex-col"style={{minHeight: '65vh'}}>
          <h1>Thank you!</h1>
          <p>Your message has been sent successfully.</p>
          <p>I will respond as soon as I get this message.</p>
          <p>Thanks again, for reaching out and contacting.</p>
        </div>
      </div>
    </section>
  </Layout>
)
